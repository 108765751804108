.news {
  margin-bottom: 100px;
  font-family: "Roboto", sans-serif;
  color: #000000;

  &__container {
    margin: 0 auto;
    // max-width: 1300px;
    @media (min-width: 650px) {
      max-width: 650px;
    }
    @media (min-width: 950px) {
      max-width: 900px;
    }
    @media (min-width: 1250px) {
      max-width: 1240px;
    }
  }
  &__title {
    font-size: 52px;
    text-align: center;
    font-weight: 800;
  }

  &__subTitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 57px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 75px;

    @media (max-width: 1400px) {
      // width: 100%;
      margin: 50px auto;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin: 50px auto;
      justify-content: center;
    }
  }

  &__item {
    width: 280px;
    border-radius: 8px;
    height: 540px;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:not(:nth-last-child(-n + 4)) {
      margin-bottom: 50px;
    }

    @media (max-width: 1400px) {
      &:not(:nth-last-child(-n + 2)) {
        margin-bottom: 50px;
      }
    }

    @media (max-width: 768px) {
      &:not(:nth-last-child(-n + 1)) {
        margin-bottom: 50px;
      }
    }
  }

  &__moreBtn {
    font-size: 24px;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px 40px;
    color: #fff;
    background-color: #004d92;
    border-radius: 8px;
    text-align: center;
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-color: #004d92;
    color: #fff;
    font-size: 18px;
    border-radius: 8px;
    text-decoration: none;
    margin-bottom: 50px;
    transition: all 0.2s ease;

    &:hover {
      color: #fff;
      background-color: #2c70ac;
    }
  }
  &__wrapper {
    &--btnMore {
      display: flex;
      justify-content: center;
    }
  }
}

.newsItem {
  &__img {
    width: 100%;
    height: 280px;
  }

  &__img img {
    border-radius: 8px;
  }

  &__wrapper {
    width: 100%;
    padding: 30px 15px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    overflow: hidden;
    height: 70px;
    

    @include onPhone {
      // font-size: 31px;
    }
  }

  &__subTitle {
    font-size: 14px;
    height: 80px;
    margin-bottom: 10px;
  }

  &__btn {
    width: 60%;
    margin: 0 auto;
    background-color: #004d92;
    padding: 15px;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: #fff !important;
    }
  }
}

.mainNew {
  width: 100% !important;
  // height: 800px !important;
  height: 620px !important;
  position: relative;
  margin-bottom: 100px;

  @include onPhone {
    height: 579px !important;
  }

  & img {
    z-index: -1;
    width: 100% !important;
    height: 620px !important;
    object-fit: cover;
    object-position: center;

    @include onPhone {
      height: 579px !important;
    }
  }

  &__Bg {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 620px !important;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;

    @include onPhone {
      height: 579px !important;
    }
  }

  &__content {
    height: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: Roboto;
    font-size: 62px;
    font-weight: 800;
    line-height: 73px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 24px;
    color: #fff;

    @include onDescktop {
      font-size: 31px;
    }
  }

  &__subtitle {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;

    @include onDescktop {
      font-size: 16px;
    }
  }

  // Це все для відображення останньої новини посередині
  // &__content {
  //   z-index: 3;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   width: 100% !important;
  //   height: 800px !important;

  //   @include onPhone {
  //     padding: 55px 0 100px;
  //     height: 579px !important;
  //   }
  // }

  // &__new {
  //   padding: 41px 30px 20px;
  //   width: 500px !important;
  //   height: max-content;
  //   border-radius: 8px;
  //   background-color: #fff;

  //   @media (max-width: 640px) {
  //     width: 90% !important;
  //     margin: 0 auto;
  //   }
  // }

  // &__btn {
  //   width: 160px;
  //   text-align: center;
  //   background-color: #004d92;
  //   padding: 10px 15px;
  //   border-radius: 8px;
  //   color: #fff;
  //   text-decoration: none;
  //   transition: all 0.3s ease;
  //   cursor: pointer;

  //   &:hover {
  //     text-decoration: underline;
  //     color: #fff !important;
  //   }
  // }

  // &__text1 {
  //   font-size: 16px;
  //   font-weight: 700;
  //   margin-bottom: 11px;
  //   color: #000;
  //   @media (max-width: 640px) {
  //     font-size: 14px;
  //   }
  // }
  // &__title {
  //   color: #004d92;
  //   font-size: 48px;
  //   line-height: 48px;
  //   font-weight: 800;
  //   margin-bottom: 28px;
  //   @media (max-width: 640px) {
  //     font-size: 28px;
  //     line-height: 32px;
  //   }
  // }

  // &__subTitle {
  //   @media (max-width: 640px) {
  //     font-size: 14px;
  //   }
  //   font-size: 16px;
  //   margin-bottom: 20px;
  // }



}

.news-active__top {
  width: 100% !important;
  height: 350px !important;
  position: relative;
  margin-bottom: 50px;

  &--bg2 {
    width: 100%;
    height: 350px;
    background-color: #000000b6;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  & img {
    z-index: 1;
    width: 100% !important;
    height: 350px !important;
    object-fit: cover;
    object-position: center;
  }

  &--content {
    margin: 0 auto;
    position: relative;
    top: -250px;
    height: 100px;
    width: 100%;
    z-index: 30;
  }

  &--title {
    color: #fff;
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 25px;

    @media (max-width: 678px) {
      font-size: 32px;
    }
  }

  &--wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;

    @media (max-width: 678px) {
      font-size: 12px;
    }
  }
}

.news-active__content {
  margin-bottom: 125px;

  &--title {
    font-weight: 800;
    font-size: 52px;
    text-align: center;
    color: #000;
    margin-bottom: 70px;

    @media (max-width: 678px) {
      font-size: 32px;
    }
  }

  &--text {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    line-height: 18px;
    text-align: justify;
  }

  &--top {

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &--photo {
    margin-bottom: 100px;
  }
  &--photo img {
    max-height: auto;
    width: 100%;
    object-fit: cover;
  }
}

.news-active__control {
  width: max-content;
  display: flex;
  justify-content: space-between;
  gap: 50px;

  &--prev {
    text-decoration: none;
    color: #000;
    font-weight: 700;
  }

  &--next {
    text-decoration: none;
    color: #000;
    font-weight: 700;
  }
}

.newsItem__img img {
  width: 280px;
  height: 280px;
  object-fit: cover;
}

.news-slider .swiper-slide img {
  object-fit: cover;
}