.stages {
  padding: 47px 0;

  &__title {
    margin-bottom: 20px;
  }

  &__subtitle {
    display: block;
    font-weight: 400px;
    font-size: 24px;
    margin-bottom: 84px;
    text-align: center;

    @include onPhone {
      text-align: left;
      font-size: 18px;
    }
  }

  &__content {
    @include onPhone {
      display: none;
    }

    display: block;
    width: 100%;
    height: 600px;
    background-image: url(../img/st-content.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &--phone {
      display: none !important;

      @include onPhone {
        display: flex !important;
      }
    }
  }

  &__card {
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 72px;
    font-size: 14px !important;

    @include onPhone {
      font-size: 18px;
    }

    &--1 {
      background-image: url(../img/1.png);
    }
    &--2 {
      background-image: url(../img/2.png);
    }
    &--3 {
      background-image: url(../img/3.png);
    }
    &--4 {
      background-image: url(../img/4.png);
    }
    &--5 {
      background-image: url(../img/5.png);
    }
    &--6 {
      background-image: url(../img/6.png);
    }
    &--7 {
      background-image: url(../img/7.png);
    }
    &--8 {
      background-image: url(../img/8.png);
    }
    &--9 {
      background-image: url(../img/9.png);
    }
    &--10 {
      background-image: url(../img/10.png);
    }
    &--11 {
      background-image: url(../img/11.png);
    }
  }
}