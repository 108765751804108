.advantages {
  padding: 47px 0;

  @include onPhone {
    padding: 36px 0 46px;
  }

  &__title {
    margin-bottom: 72px;

    @include onPhone {
      margin-bottom: 32px;
    }
  }

  &__card {
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.35);
    padding: 41px 25px;
    height: 213px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 40px;

    @media (min-width: 575px) and (max-width: 1360px) {
      height: 170px !important;
    }

    @include onPhone {
      height: 141px;
      padding: 33px 13px 33px;
    }

    &--title {
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;

      @include onPhone {
        font-size: 21px;
      }
    }

    &--text {
      font-weight: 400;
      font-size: 18px;
      @include onPhone {
        font-size: 16px;
      }
    }
  }
}