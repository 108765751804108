.sub-form {
  padding: 47px 0;

  &__title {
    text-align: center;
    margin-bottom: 20px;

    @include onPhone {
      text-align: left;
    }
  }

  &__subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 37px;

    @include onPhone {
      text-align: left;
      font-size: 18px;
    }
  }
}

.form {
  &__inp {
    padding: 15px 20px !important;
    &:focus {
      outline: 1px solid #004d92 !important;
      box-shadow: none !important;
    }
  }

  &__btn {
    border: 1px solid #004d92 !important;
    border-radius: 8px !important;
    width: 200px;
    background-color: #004d92 !important;
    height: 56px;
    font-weight: 800 !important;
    font-size: 18px !important;
    text-align: center;

    @include onPhone {
      font-size: 16px !important;
      width: 100%;
    }

    &:hover {
      background-color: #1f73bd !important;
      border: #1f73bd;
      transition: all 0.5s;
    }
  }
}
