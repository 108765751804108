.news-slider .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.news-slider .swiper-slide {
  background-position: center;
  background-size: cover;
  height: 400px;
  max-width: 100%;
}

.news-slider .swiper-slide img {
  display: block;
  max-width: 100%;
  /* object-fit: scale-down; */
}

.news-slider {
  margin-bottom: 100px;
}

@media (max-width: 600px) {
  .news-slider .swiper-slide {
    width: 100%;
    height: 300px;
  }
  
}
.swiper-button-prev, .swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  color: #fff;
  background-color: #004d92 !important;
  border-radius: 50%;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  
  font-size: 1rem !important;
}



/* 

.carousel {
  position: relative;
}
.carousel-item img {
  object-fit: cover;
}
#carousel-thumbs {
  background: rgba(255,255,255,.3);
  bottom: 0;
  left: 0;
  padding: 0 50px;
  right: 0;
}
#carousel-thumbs img {
  border: 5px solid transparent;
  cursor: pointer;
}
#carousel-thumbs img:hover {
  border-color: rgba(255,255,255,.3);
}
#carousel-thumbs .selected img {
  border-color: #fff;
}
.carousel-control-prev,
.carousel-control-next {
  width: 50px;
}
@media all and (max-width: 767px) {
  .carousel-container #carousel-thumbs img {
    border-width: 3px;
  }
}
@media all and (min-width: 576px) {
  .carousel-container #carousel-thumbs {
    position: absolute;
  }
}
@media all and (max-width: 576px) {
  .carousel-container #carousel-thumbs {
    background: #ccccce;
  }
} */