
.answer {
  &__title {
    font-size: 62px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    margin-bottom: 47px;

    @include onPhone {
      font-size: 32px !important;
    }
  }

  &__bg {
    padding: 60px 0 60px;
    position: relative;
    background-image: url(./../img/header__bg__blur_old.png) !important;
    width: 100%;
    min-height: 620px;
    background-position: center;
    background-size: cover;
    z-index: 0;

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }
  }

  &__tabs {
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    gap:40px;

    @media (min-width: 990px) and (max-width: 1400px) {
      justify-content: flex-start;
      gap
      :20px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      flex-direction: column;
      gap:20px;
    }
  }

  &__tab {
    z-index: 10;
    background-color: #fff !important;
    width: 200px;
    height: 145px;
    border-radius: 8px !important;
    color: #000 !important;
    padding: 27px 15px !important;
    font-size: 24px;
    font-weight: 800;
    transition: all 0.2s;

    @include onPhone {
      font-size: 18px !important;
    }
  }

  &__title-ac {
    margin-bottom: 40px;
  }

  &__ac {
    padding: 47px 0;
  }
}



.acord {
  &__item {
    margin-bottom: 40px;
    // border-radius: 8px !important;
    border: 1px solid #e2e2e2 !important;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.35) !important;

    &--title {
      font-weight: 700 !important;
      font-size: 24px !important;
      color: #000 !important;

      @include onPhone {
        font-size: 18px !important;
      }
    }

    &--subtitle {
      font-size: 20px;

      @include onPhone {
        font-size: 18px !important;
      }
    }

    &--text {
      font-size: 18px;

      @include onPhone {
        font-size: 16px !important;
      }
    }

    &--contact {
      font-size: 18px;

      @include onPhone {
        font-size: 16px !important;
      }
    }
  }
}

.nav-link.active {
  border-bottom: 5px solid #004d92 !important;
}

.accordion-button::after {
  background-image: url(../img/opener.png) !important;
  width: 15px !important;
  height: 15px !important;
  background-position: center;
}

.accordion-button:not(.colapsed) {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(225deg) !important;
}