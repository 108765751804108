.history {
  padding: 47px 0;

  &__top {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    @include onPhone {
      font-size: 16px;
      text-align: left;
    }
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 62px;
    text-align: center;


  }

  &__img-1 {
    margin: 0 auto;
    max-height: 390px;
    max-width: 100%;
  }

  &__img-2 {
    margin: 0 auto;
    max-width: 100%;
    max-height: 390px;
  }

  &__content-lg {
    display: flex !important;

    @include onPhone {
      display: none !important;
    }
  }

  &__content-sm {
    display: none !important;

    & img {
      width: 100%;
      object-fit: contain;
    }

    &--container {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    @include onPhone {
      display: block !important;
    }
  }
}

.hist {
  display: flex;
  flex-direction: column;

  &__line-1 {
    width: 67px;
    height: 13px;
    background-color: #004d92;
    margin-bottom: 5px;
  }

  &__line-2 {
    width: 134px;
    height: 13px;
    background-color: #2668a2;
    margin-bottom: 5px;
  }

  &__line-3 {
    width: 201px;
    height: 13px;
    background-color: #598bb8;
    margin-bottom: 5px;
  }

  &__line-4 {
    width: 268px;
    height: 13px;
    background-color: #8cafce;
    margin-bottom: 5px;
  }

  &__line-5 {
    width: 335px;
    height: 13px;
    background-color: #bfd3e4;
    margin-bottom: 5px;
  }

  &__title {
    color: #000;
    font-size: 27px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  &__year {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
