.footer {
  width: 100%;
  min-height: 400px;
  background-color: #004d92;
  padding: 60px 0;

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-around;

    @include onPhone {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 27px;
    }
    
    margin-bottom: 60px;
  }

  &__nav {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (max-width: 990px) {
      flex-direction: column;
    }

    &--item {
      text-decoration: none;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      text-align: left;

      @include onPhone {
        font-size: 18px;
      }

      &:hover {
        color: #fff;
      }

      @media (min-width: 990px) {
        // &:not(:nth-last-child(-n+2)) {
        //   margin-right: 80px;
        // }
        // &:not(:last-child) {
        //   margin-right: 80px;
        // }
      }

      @media (max-width: 990px) {
        margin-bottom: 20px;
      }
    }
  }

  &__logo {
    width: 270px;
    height: 165px;

    @include onPhone {
      margin-bottom: 50px;
      width: 84px;
      height: 51px;
    }
  }

  &__line {
    height: 1px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 25px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include onPhone {
      flex-direction: column;
    }
  }

  &__desc {
    font-weight: 300;
    color: #fff;
    font-size: 16px;

    @include onPhone {
      text-align: center;
      margin-bottom: 40px;
      font-size: 12px;
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    &--text {
      @include onPhone {
        display: none;
      }
      margin-right: 20px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }

    &--image {
      margin-right: 20px;
      width: 45px;
      height: 45px;
      background-color: #1f73bd;
      border-radius: 50%;

      @include onPhone {
        width: 25px;
        height: 25px;
      }
    }

    &--image-facebook {
      background-image: url(../img/facebook.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 30px 30px;

      @include onPhone {
        background-size: 20px 20px;
      }
    }

    &--image-inst {
      background-image: url(../img/inst.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 25px 25px;

      @include onPhone {
        background-size: 14px 14px;
      }
    }
    &--image-lk {
      background-image: url(../img/lk.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 25px 25px;

      @include onPhone {
        background-size: 14px 14px;
      }
    }
  }

  &__contact {
    &--link {
      transition: all 0.2s;
      text-decoration: none;
      color: #fff;
      font-size: 14px;

      @include onPhone {
        font-size: 14px;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
