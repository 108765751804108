* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "mixins.scss";

#root {
  font-family: "Roboto", sans-serif;
  color: #000000;
  background-color: #ffffff;
}

div.row {
  margin: 0;
}

body {
  overflow-x: hidden;
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: 52px;

  @include onPhone {
    font-size: 25px;
    text-align: left;
  }
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background-color: #004d92 !important;

  @include onPhone {
    width: 7px !important;
    height: 7px !important;
  }
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 40px;

  & img {
    max-width: 100%;
  }
}



@import 'header.scss';
@import 'advantages.scss';
@import 'franchise-list.scss';
@import 'stages.scss';
@import 'shop.scss';
@import 'calculate.scss';
@import 'history.scss';
@import 'footer.scss';
@import 'answerPage.scss';
@import 'form.scss';
@import 'maps.scss';
@import 'login.scss';
@import 'news.scss';


.mdd {
  position: absolute;
  background-color: #000000;
  z-index: 1000;
  width: 100%;
  height: 100vh;
}
