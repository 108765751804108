.login {
  &__faceHeader {
    height: 100px;
    background-color: #004d92;
    margin-bottom: 137px;
  }

  &__faceHeader img {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  &__content {
    margin: 0 auto;
    width: max-content;
    padding: 40px 60px;
    border: 2px solid #004d92;
    border-radius: 20px;
  }

  &__title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 38px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 47px;

    @media (max-width: 1000px) {
      flex-direction: column;
      
    }
  }

  &__wrapper .form__label input {
    width: 350px;
    height: 50px;
    border: 2px solid #004d92;
    border-radius: 10px;
    padding-left: 15px;
  }

  &__form .form__button {
    background-color: #004d92;
    color: #fff;
    font-size: 24px;
    padding: 10px 45px;
    border-radius: 8px;
    border: 2px solid #004d92 !important;
    outline: none !important;
  }

  @media (max-width: 550px) {
    &__form .form__button {
      width: 100%;
    }
    &__wrapper .form__label input {
      width: 100%;
    }

    &__content {
      margin: 0 auto;
      width: 100%;
      padding: 40px 60px;
      border: 2px solid #004d92;
      border-radius: 20px;
    }

    &__wrapper {
      width: 100%;
    }
  }
}