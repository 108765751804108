.header {
  &__bg {
    z-index: 0;
    position: relative;
    max-width: 100%;
    // min-height: 800px;
    min-height: 800px;
    background-image: url(../img/header__bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // padding: 222px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include onPhone {
      padding: 0;
      // padding: 55px 0 100px;
      min-height: 500px;
    }

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }
  }

  &__title {
    max-width: 700px;
    font-weight: 800;
    color: #fff;
    font-size: 62px;
    margin-bottom: 20px;

    @include onPhone {
      font-size: 32px;
    }
  }

  &__text {
    max-width: 700px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 50px;
    font-size: 24px;

    @include onPhone {
      font-size: 16px;
    }
  }

  &__btn {
    z-index: 10;
    font-size: 18px;
    font-weight: 800;
    padding: 18px 22px;
    background-color: #004d92;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    display: block;
    width: 194px;

    @include onPhone {
      width: 170px;
      padding: 10px 5px;
      text-align: center;
      font-size: 16px;
    }

    &:hover {
      transition: all 0.5s;
      background-color: #1f73bd;
      color: #fff;
    }
  }

  &__logo {
    width: 123px;
    height: 75px;

    @include onPhone {
      width: 84px;
      height: 51px;
    }
  }

  &__top {
    @include onPhone {
      height: 72px;
    }

    height: 100px;
    background-color: #004d92;

    &--flex {
      @include onPhone {
        height: 72px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__nav {
    @include onPhone {
      display: none;
    }

    & li {
      line-height: 100px;
    }

    list-style: none;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      display: flex;
    }

    &-link {
     
      text-decoration: none;
      position: relative;

      &:hover {
        color: #fff;
        text-decoration: none;
  
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;
          height: 3px;
          /* Товщина підкреслення */
          background-color: #ffffff;
          /* Замініть на ваш колір підкреслення при наведенні */
        }
      }
    }
  }

  &__nav-link {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-right: 30px;
    text-decoration: none;
    
    @media (min-width: 992px) {
      font-size: 18px;
    }

    &:hover {
      color: #fff;
    }
  }

  &__active {
    font-weight: 700;
  }

  &__bottom {
    padding: 80px;
    min-height: 262px;
    background-color: #004d92;

    @include onPhone {
      padding: 30px 0 56px;
    }

    &--card {
      text-align: start;
      width: 280px;

      @include onPhone {
        width: 213px;
        margin-bottom: 50px;
      }
    }

    &--price {
      font-size: 50px;
      font-weight: 800;
      color: #fff;
      margin-bottom: 15px;

      @include onPhone {
        font-size: 35px;
      }
    }

    &--text {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px;
      color: #fff;

      @include onPhone {
        font-size: 15px;
      }
    }

    &--line {
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
  }

  &__burger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 23px;
    width: 30px;

    @include onPhone {
      width: 24px;
      height: 18px;
    }

    &--item {
      height: 3px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.header__active {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 3px;
    /* Товщина підкреслення */
    background-color: #ffffff;
    /* Замініть на ваш колір підкреслення при наведенні */
  }
}

.header-burger {
  &__close {
    background-image: url(../img/cross.svg);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 6%;
    background-size: cover;
    background-position: center;
    right: 10%;
    opacity: 0.9;
  }

  &__logo {
    width: 123px;
    height: 75px;
  }

  &__top {
    padding: 50px 0;
    height: 800px;
    background-color: #004d92;

    &--answer {
      height: 800px;
    }

    &--flex {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
    }
  }

  &__nav {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  &__nav-link {
    position: relative;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    margin-right: 30px;
    text-decoration: none;


    &:hover {
      color: #fff;
      text-decoration: none;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 3px;
        /* Товщина підкреслення */
        background-color: #ffffff;
        /* Замініть на ваш колір підкреслення при наведенні */
      }
    }
  }

  &__active {
    font-weight: 700;
  }
}